<template>
  <div>
    <v-card>
      <v-card-title>
        Dispositivos
        <v-spacer></v-spacer>
        <v-btn icon color="success" @click="loadData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisa"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="getDevices" :search="search">
            <template slot="item.deviceType" slot-scope="{ item }">
              {{ getDeviceTypeNameById(item.deviceType) }}
            </template>
            <template slot="item.enabled" slot-scope="{ item }">
              {{ item.enabled ? "SIM" : "NÃO" }}
            </template>
            <template slot="item.clientId" slot-scope="{ item }">
              {{
                item.clientId
                  ? $store.getters.getClienteNameById(item.clientId)
                  : ""
              }}
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <!-- botão editar -->
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editar(item)"
                    color="warning"
                    class="mr-1"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar : {{ item.name }}</span>
              </v-tooltip>
              <!-- botão excluir -->
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="excluir(item)"
                    color="red"
                    class="mr-1"
                    >mdi-trash-can</v-icon
                  >
                </template>
                <span>Excluir : {{ item.name }}</span>
              </v-tooltip>
              <!-- botão comandos -->
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="openCommand(item)"
                    >mdi-powershell</v-icon
                  >
                </template>
                <span>Comandar : {{ item.name }}</span>
              </v-tooltip>
              <!-- interface de configuração -->
              <v-tooltip left v-if="item.deviceType == 'wifiwsmp3'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      $router.push({
                        path: '/device/wifiDevice',
                        query: {
                          deviceId: item.id,
                        },
                      })
                    "
                    >mdi-wifi-star</v-icon
                  >
                </template>
                <span>Configurar : {{ item.name }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- dialog de cadastro -->
    <v-dialog v-model="dialogCadastro" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2"
          >{{ item._id ? "Editar" : "Cadastrar" }} Dispositivo<v-spacer
          ></v-spacer>
          <v-btn icon @click="dialogCadastro = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formDocument">
            <!-- tipo do dispositivo -->
            <v-autocomplete
              v-model="item.deviceType"
              :items="getDevicesAvailable"
              label="Modelo de dispositivo"
              outlined
              item-text="name"
              item-value="class"
              @change="selectDevice"
            ></v-autocomplete>
            <!-- card com os dados do device -->
            <v-card outlined class="mb-6" v-show="inputs.length">
              <v-card-subtitle>Configuração do dispositivo</v-card-subtitle>
              <v-card-text>
                <div v-for="input in inputs" :key="input.attr">
                  <v-autocomplete
                    v-if="input.type == 'autocomplete'"
                    v-model="item.config[input.attr]"
                    :items="input.items"
                    :label="input.name || ''"
                    :hint="input.placeholder || ''"
                    persistent-hint
                    outlined
                    :rules="input.required ? required : []"
                    :multiple="input.multiple"
                    :chips="input.multiple"
                    :small-chips="input.multiple"
                    :deletable-chips="input.multiple"
                    :append-outer-icon="input.showAppend ? 'mdi-plus' : ''"
                    @click:append-outer="openAppendData(input)"
                  >
                  </v-autocomplete>
                  <v-text-field
                    v-if="input.type == 'text' || input.type == 'number'"
                    v-model="item.config[input.attr]"
                    :label="input.name || ''"
                    :hint="input.placeholder || ''"
                    :type="input.type || 'text'"
                    persistent-hint
                    outlined
                    :rules="input.required ? required : []"
                  >
                  </v-text-field>
                  <v-card outlined v-if="input.type == 'boolean'">
                    <v-card-text>
                      <v-switch
                        v-model="item.config[input.attr]"
                        :label="input.name || ''"
                      ></v-switch>
                      <span>
                        {{ input.placeholder }}
                      </span>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
            <!-- Nome do dispositivo -->
            <v-text-field
              label="Nome do dispositivo"
              v-model="item.name"
              outlined
              :rules="required"
            >
            </v-text-field>
            <!-- descrição  -->
            <v-textarea
              v-model="item.description"
              label="Descrição"
              outlined
            ></v-textarea>
            <!-- ativo -->
            <v-row>
              <v-col cols="auto">
                <v-switch
                  v-model="item.stayEnabled"
                  label="Manter ativo sem automações"
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-switch v-model="item.enabled" label="Ativo"></v-switch>
              </v-col>
            </v-row>
            <!-- card com as dicas do dispositivo -->
            <v-card v-show="deviceSelected.description" outlined class="mb-2">
              <v-card-subtitle>
                <v-icon class="mx-2">mdi-information</v-icon>
                Informações sobre o equipamento
              </v-card-subtitle>
              <v-card-text>
                <pre>
                  {{ deviceSelected.description }}
                </pre>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="success" @click="save()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAppend" max-width="500px">
      <v-card>
        <v-card-title>Adicionar valores</v-card-title>
        <v-card-text class="pb-0 pt-2">
          <div v-for="app in appendData.append" :key="app.attr">
            <v-text-field
              v-if="app.type == 'text' || app.type == 'number'"
              v-model="app.value"
              :label="app.name || ''"
              :hint="app.placeholder || ''"
              :type="app.type || 'text'"
              persistent-hint
              outlined
              :rules="app.required ? required : []"
            >
            </v-text-field>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="dialogAppend = false">Cancelar</v-btn>
          <v-btn color="success" @click="addData(appendData)">
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogCadastro = true"
          v-show="$route.query.clientId"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar</span>
    </v-tooltip>
    <Command v-model="dialogCommand" :device="item" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Command from "./components/Command.vue";
export default {
  name: "DispositivosView",
  components: {
    Command,
  },
  data: () => ({
    dialogCadastro: false,
    dialogCommand: false,
    dialogAppend: false,
    search: "",
    item: {
      config: {},
      enabled: true,
    },
    tip: null,
    deviceSelected: {},
    inputs: [],
    required: [(v) => !!v || "Campo obrigatório"],
    headers: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Nome do Equipamento",
        align: "start",
        value: "name",
      },
      { text: "Modelo", value: "deviceType" },
      { text: "Descrição", value: "description" },
      { text: "Ativo", value: "enabled" },
      { text: "Cliente", value: "clientId" },
      { text: "Ações", value: "actions" },
    ],
    appendData: [],
  }),
  computed: {
    ...mapGetters([
      "getDevicesAvailable",
      "getDevices",
      "getDeviceTypeNameById",
    ]),
  },
  methods: {
    selectDevice() {
      if (this.item.deviceType) {
        this.deviceSelected = this.getDevicesAvailable.find(
          (device) => device.class == this.item.deviceType
        );
        this.inputs = this.deviceSelected.configAttributes;
        // caso a config esteja vazia, preenche com o config do device
        if (Object.keys(this.item.config) == 0) {
          this.item.config = this.deviceSelected.config;
        }
      } else {
        this.tip = "Selecione um modelo de dispositivo";
      }
    },
    save() {
      if (!this.$refs.formDocument.validate()) {
        return;
      }
      this.item.clientId = this.$route.query.clientId;
      this.item.callback = () => {
        this.closeDialog();
        this.item = {
          config: {},
          enabled: true,
        };
      };
      this.$store.dispatch("saveDevice", this.item);
    },
    editar(item) {
      this.item = item;
      this.selectDevice();
      this.dialogCadastro = true;
    },
    openCommand(item) {
      this.item = item;
      this.selectDevice();
      this.dialogCommand = true;
    },
    excluir(item) {
      this.$store.dispatch("deletDevice", item);
    },
    closeDialog() {
      this.dialogCadastro = false;
      this.item = {
        config: {},
        enabled: true,
      };
      this.$refs.formDocument.reset();
    },
    loadData() {
      this.$store.dispatch("loadDevicesAvailable");
      if (this.$route.query.clientId) {
        this.$store.dispatch(
          "loadDevicesByCLientId",
          this.$route.query.clientId
        );
      } else {
        this.$store.dispatch("loadDevices");
      }
    },
    openAppendData(item) {
      this.appendData = item;
      this.dialogAppend = true;
    },
    addData(item) {
      let i = this.inputs.find((input) => input.attr == item.append[0].attr);
      i.items.push(item.append[0].value);
      if (this.item.config[item.append[0].attr] == undefined) {
        this.item.config[item.append[0].attr] = [];
      }
      this.item.config[item.append[0].attr].push(item.append[0].value);
      this.dialogAppend = false;
    },
  },
  mounted() {
    this.$store.dispatch("loadDevicesAvailable");
    this.loadData();
    if (this.$route.query.clientId) {
      this.headers.splice(5, 1);
    }
  },
};
</script>

<style></style>