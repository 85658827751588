<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-bind:value="value"
    scrollable
  >
    <v-card>
      <v-toolbar color="primary" dark
        >Comandar o dispositivo
        <i
          ><b class="ml-2">{{ device.id }} - {{ device.name }}</b></i
        ></v-toolbar
      >
      <v-card-text>
        <!-- card com os botões de ação -->
        <v-card>
          <v-card-subtitle> Ações </v-card-subtitle>
          <v-card-text>
            <div class="d-flex flex-wrap justify-space-between">
              <v-btn
                v-for="ac in config.actions"
                :key="ac.event"
                class="ma-1"
                @click="sendCommand(ac.event)"
              >
                {{ ac.name }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-if="config.state">
          <v-card-subtitle> Estado </v-card-subtitle>
          <v-card-text>
            <!-- mostra o conectado -->
            <v-row v-if="typeof config.state.connected == 'boolean'">
              <v-col>
                Conectado:
                <b>{{ config.state.connected ? "Sim" : "Não" }}</b>
              </v-col>
            </v-row>
            <v-row v-if="typeof config.state.playing == 'boolean'">
              <v-col>
                Tocando:
                <b>{{ config.state.playing ? "Sim" : "Não" }}</b>
              </v-col>
            </v-row>
            <!-- mostra as entradas -->
            <v-row v-if="Array.isArray(config.state.inputs)">
              <v-col v-for="(iIN, idx) in config.state.inputs" :key="idx">
                <v-btn text :color="iIN ? 'red' : 'green'"
                  >Ent. {{ idx + 1 }} {{ iIN ? "Fechada" : "Aberta" }}</v-btn
                >
              </v-col>
            </v-row>
            <!-- mostra as saidas -->
            <v-row v-if="Array.isArray(config.state.outputs)">
              <v-col v-for="(out, idx) in config.state.outputs" :key="idx">
                <v-btn text :color="out ? 'red' : 'green'"
                  >Sai. {{ idx + 1 }} {{ out ? "Ligada" : "Desligada" }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="config.state && Array.isArray(config.state.info)">
          <v-card-subtitle> Informações </v-card-subtitle>
          <v-card-text>
            <!-- mostra as informações -->
            <v-row v-for="(info, idx) in config.state.info" :key="idx">
              <v-col cols="auto">
                  <span class="font-weight-black">{{ info.text }}: </span>
              </v-col>
              <v-col>
                  <span class="font-weight-medium">{{ info.value }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="$emit('input', false)" color="success"
          >Fechar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeviceCommand",
  props: {
    device: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    dialogCadastro: false,
    config: {},
    ws: null,
    wsUrl: "",
  }),
  computed: {},
  methods: {
    loadConfig() {
      this.$http("device/state/" + this.device.id)
        .then((res) => {
          this.config = res.data;
          console.log(this.config);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendCommand(command) {
      this.$http
        .post("device/action/", {
          deviceId: this.device.id,
          command: command,
        })
        .then(() => {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Ação enviada com sucesso");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateState(message) {
      this.config.state = JSON.parse(message.data);
    },
  },
  watch: {
    value: function (value) {
      if (value) {
        this.loadConfig();
        // usado para iniciar a conexão com o websocket em produção e desenvolvimento
        this.wsUrl = "";
        if (process.env.NODE_ENV === "production") {
          this.wsUrl =
            `${window.location.protocol}//${window.location.host}/api/device/${this.device.id}`
              .replace("http", "ws")
              .replace("https", "wss");
        } else {
          this.wsUrl = `${this.$http.defaults.baseURL}device/${this.device.id}`
            .replace("http", "ws")
            .replace("https", "wss");
        }
        this.wsUrl += this.$store.getters.getImgTokenQuery;
        console.log(this.wsUrl);
        this.ws = new WebSocket(this.wsUrl);
        this.ws.onmessage = this.updateState;
      } else {
        this.config = {};
        this.ws.removeEventListener("message", this.updateState);
        this.ws.close();
      }
    },
  },
};
</script>

<style>
</style>